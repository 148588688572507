<template>
  <nav
    class="w-full h-16 bg-surface flex items-center justify-center z-40 fixed top-0"
    :class="{ showSidebar: 'pl-70' }"
  >
    <div
      class="w-full flex h-16 items-center px-5 justify-between max-w-[70rem]"
      :class="{ 'md:justify-end md:max-w-[100rem]': authUser.isLoggedIn }"
    >
      <img
        :src="organization?.logoUrl || ExfluentialLogoSvg"
        alt="Organization logo"
        class="h-full aspect-square object-contain block"
        :class="{ 'md:hidden': authUser.isLoggedIn }"
      />
      <div class="flex gap-2 items-center h-full">
        <div :class="{ 'md:pr-6': showHamburgerMenu }">
          <LocaleSwitcher />
        </div>
        <div v-if="showSignInButton" class="flex gap-2 items-center">
          <Btn
            :variant="BtnVariant.FILLED"
            :size="BtnSize.LG"
            :color="BtnColor.PRIMARY"
            to="/auth/login"
            class="w-full whitespace-nowrap"
          >
            {{ $t('layout.sign-in') }}
          </Btn>
        </div>
        <div v-if="isLeftSideBarAvailable" class="md:hidden">
          <Btn
            icon
            aria-label="Toggle left menu"
            @click="emit('toggleLeftSidebar')"
          >
            <Icon :icon="DashboardSvg" />
          </Btn>
        </div>
        <div v-if="showHamburgerMenu">
          <Btn
            icon
            aria-label="Toggle right menu"
            @click="emit('toggleRightSidebar')"
          >
            <Icon :icon="HamburgerSvg" />
          </Btn>
        </div>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { Btn, BtnColor, BtnSize, BtnVariant } from '~/components/btn';
import HamburgerSvg from '~/assets/icons/hamburger.svg?component';
import DashboardSvg from '~/assets/icons/dashboard.svg?component';
import ExfluentialLogoSvg from '~/assets/images/exfluential-logo.svg?url';
import { useAuthUserStore } from '~/store/auth-user';
import { useOrganizationStore } from '~/store/organization';
import { storeToRefs } from 'pinia';
import { Icon } from '~/components/icon';
import { useRoute } from 'nuxt/app';
import { computed } from '#imports';
import { Nullable } from '~/types/common';
import { HubDto } from '~/api/hubs';
import { LocaleSwitcher } from '~/layouts/_components/top-navigation/_components/locale-switcher';

type Props = {
  isLeftSideBarVisible?: boolean;
  isLeftSideBarAvailable?: boolean;
  isLeftSideBarToggled?: boolean;
  isSignInButtonVisible?: boolean;
  hub: Nullable<HubDto>;
};

const props = defineProps<Props>();
const emit = defineEmits(['toggleRightSidebar', 'toggleLeftSidebar']);

const authUser = useAuthUserStore();
const organizationStore = useOrganizationStore();
const { organization } = storeToRefs(organizationStore);
const route = useRoute();
const hubSlug = computed<string>(() => route.params.hubslug as string);

const showSignInButton = computed<boolean>(() => {
  return (
    props.isSignInButtonVisible !== false &&
    !authUser.isLoggedIn &&
    organization.value?.displaySignInButton === true
  );
});

const showHamburgerMenu = computed<boolean>(() => {
  if (!organization.value) {
    return false;
  }
  if (route.name === 'promote' && organization.value.isPromoteMenuEnabled) {
    return true;
  }
  if (hubSlug.value) {
    return (
      props.hub?.isMenuEnabled ||
      props.hub?.displayCtaButton ||
      organization.value?.isMenuEnabled
    );
  }

  return organization.value?.isMenuEnabled;
});
</script>
